import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Founder coaching`}</h2>
    <p>{`I work with clients in 6 month intervals, up to 2 years. We'll chat for 90 minutes twice per month, and I'm fast at answering questions over text/email.`}</p>
    <p>{`Our conversations will focus on how you can scale yourself as a founder, and design systems to support your team. While we'll focus much of our time on the problems most top of mind for you, it's important to supplement this with areas that may be tough or touchy subjects. I'm also able to support you with more short term operational advice during periods when you don't have mental bandwidth for deeper work.`}</p>
    <p>{`A few areas where I've commonly supported founders:`}</p>
    <ul>
      <li parentName="ul">{`Expanding ambition and overcoming limiting beliefs`}</li>
      <li parentName="ul">{`Goal setting and prioritization team wide`}</li>
      <li parentName="ul">{`Increasing trust in and agency of execs/cofounders`}</li>
      <li parentName="ul">{`Shifting approach to leadership during crisis`}</li>
      <li parentName="ul">{`Organizational culture and management style`}</li>
      <li parentName="ul">{`Operational discipline`}</li>
      <li parentName="ul">{`Fundraising and FP&A`}</li>
    </ul>
    <p>{`Back when I was running Make School, I struggled to find a great thought partner to help me grow as a CEO. It was particularly tough to find coaches or mentors who could balance the strategic/systems challenges with the emotional/interpersonal challenges.`}</p>
    <p>{`The style of coaching I offer blends strategic/operational thinking and inward/emotional development. I seek to deliver a balance between supporting you with your development as a leader and helping you tackle the practical challenges of building an organization.`}</p>
    <h3>{`Testimonials`}</h3>
    <blockquote>
      <p parentName="blockquote">{`“Ashu has been a valuable thought partner across strategic, product, and leadership topics. His communication/pitch feedback helped us expediently close our Series C. He’s among the sharpest thinkers in the YC community.”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“The best thing about having Ashu as a coach is how effortlessly he pulls from shared experiences to help me tackle some of the hardest challenges I am confronting in business. He is thorough and I leave our sessions with at least one thing I can do to move the needle.”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“Ashu has been essential for our journey as a company and myself as a founder. Being always available and providing advice that is not only honest and relevant, but also motivating. It's the perfect balance between someone who's been through the journey and just 'gets it' while also seen enough to give the right advice and understand nuances.”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“Ashu has been invaluable in navigating the early stage CEO journey, including the difficult decision to shut down my previous startup and wander through the idea maze for my new one. His long term perspective and keen awareness of my habits have pushed me to operate more consistently, especially when I'm tempted to instead operate out of fear. I've come to trust him as much as I would a cofounder, and I'm excited to continue working with him!”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“Ashu really believed in us. I'll never forget when he looked us dead in the eye and said 'you guys should pursue enterprise contracts. you can charge 6 figures'. It's the reason we went from charging people $250/mo to $8K/mo and hockey stick'd our revenue. He leads by example in being transparent and vulnerable as a leader.”`}</p>
    </blockquote>
    <p><em parentName="p">{`(left anonymous to preserve confidentiality)`}</em></p>
    <h3>{`In conversation`}</h3>
    <Video videoId="J5g1vn30XKY" alt="Founder Coaching Discussion" wide mdxType="Video" />
    <h3>{`Format and expectations`}</h3>
    <p>{`We'll have 1-2 introductory calls to gauge a sense of fit. Often founders like to meet 3-4 coaches to gain better clarity on what they're looking for.`}</p>
    <p>{`If we decide to work together, we'll sign a lightweight agreement outlining basics (dates, payment, confidentiality). The agreement and 6 month period are to set expectations / check in point, not a lock in - I want founders to feel wholly supported and unencumbered :)`}</p>
    <p>{`We'll start with biweekly calls following this rough format, and iterate on it as we build rapport.`}</p>
    <ul>
      <li parentName="ul">{`15m - reflect on the last two weeks (action items // what's been top of mind)`}</li>
      <li parentName="ul">{`15m - discussion of short-term topics / burning priorities`}</li>
      <li parentName="ul">{`15m - discussion of long-term topics / personal growth areas`}</li>
      <li parentName="ul">{`15m - come up with action items for the next two weeks`}</li>
      <li parentName="ul">{`30m - buffer for topics to go long (important that sessions don't feel rushed, but it's OK to end early)`}</li>
    </ul>
    <h3>{`Rates`}</h3>
    <p>{`I typically charge the following rates:`}</p>
    <ul>
      <li parentName="ul">{`Nonprofit - $1k/mo`}</li>
      <li parentName="ul">{`Seed - $2k/mo`}</li>
      <li parentName="ul">{`Series A - $3k/mo`}</li>
      <li parentName="ul">{`Series B - $4k/mo`}</li>
      <li parentName="ul">{`Series C - $5k/mo`}</li>
    </ul>
    <p>{`If you run an impact-focused organization and can't afford these rates, I'll do my best to accommodate.`}</p>
    <p>{`Matt Schulman did some great `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/posts/matt-schulman-15911861_ceo-executive-coach-compensation-benchmarks-activity-7024830363961098240-UUQk/?utm_source=share&utm_medium=member_desktop"
      }}>{`research on coaching rates`}</a>{` from YC / a16z founders:`}</p>
    <img {...{
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1694724432/Coaching%20Site/coaching-rates.jpg",
      "width": "100%"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      